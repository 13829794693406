import { CardLockRequest } from 'components/card-domain/types';
import { AcpMedia, BrandingRootScopeService } from 'core';

import { AcpCardClient } from './acp-card-client';
import { CardImage, CardResponse } from './types';

export class AcpCardModel {
  public cardLockStatus;
  public isVirtualCardAllowed = this.nsProperty.create<boolean>();
  private cards = this.nsProperty.create<CardResponse[]>();
  private allCards = this.nsProperty.create<CardResponse[]>();
  constructor(
    private acpVirtualCardsModel: any,
    private acpCardClient: AcpCardClient,
    private nsProperty: nsUtils.NsPropertyService,
    private $q: ng.IQService,
    private ACP_CARD_CONSTANTS: any,
    private acpCoreDispatcher: any,
    private acpMedia: AcpMedia,
    private $window: ng.IWindowService,
    private $rootScope: BrandingRootScopeService
  ) {
    'ngInject';
    this.acpCoreDispatcher.virtualCard.virtualCardsUpdate.onValue(() => {
      this.allCards.reset();
    });
  }

  public setIsVirtualCardAllowed(type: boolean): void {
    this.isVirtualCardAllowed.set(type);
  }

  public getIsVirtualCardAllowed(): boolean {
    return this.isVirtualCardAllowed.getValue();
  }

  public getActiveCards(cards: CardResponse[]): CardResponse[] {
    return cards.filter((card) => {
      return this.filterActivatedLockedCard(card);
    });
  }

  public resetCards(): void {
    this.cards.reset();
    this.allCards.reset();
    this.getCards(false).then(() =>
      this.acpCoreDispatcher.card.cardsUpdate.emit()
    );
  }

  public getInactiveCards(cards: CardResponse[]): CardResponse[] {
    return cards.filter((card) => {
      return !this.filterActivatedLockedCard(card);
    });
  }

  public async getCards(needCached: boolean): Promise<CardResponse[]> {
    const deferred = this.$q.defer<CardResponse[]>();
    if (needCached && this.cards.getValue()) {
      deferred.resolve(this.cards.getValue());
    } else {
      let cards = await this.acpVirtualCardsModel.getAllPhysicalCards(
        !needCached
      );
      cards = cards.sort(this.sortHelper.bind(this));
      this.cards.set(cards);
      deferred.resolve(cards);
    }
    return deferred.promise;
  }

  public async getCard(cardId: string): Promise<CardResponse> {
    const cards = await this.getCards(true);
    const cardArr = cards.filter((card) => {
      return card.id === cardId;
    });
    return cardArr[0];
  }

  public async getAllCards(needCached: boolean): Promise<CardResponse[]> {
    if (needCached && this.allCards.getValue()) {
      return this.allCards.getValue();
    } else {
      let cards = await this.getCards(true);
      let allCards: CardResponse[];
      cards = this.addCardCategory(
        cards,
        this.ACP_CARD_CONSTANTS.CARD_CATEGORY.PHYSICAL
      );
      if (this.isVirtualCardAllowed.getValue()) {
        let virtualCards = await this.acpVirtualCardsModel.getAllVirtualCards();
        virtualCards = this.addCardCategory(
          virtualCards,
          this.ACP_CARD_CONSTANTS.CARD_CATEGORY.VIRTUAL
        );
        allCards = cards.concat(virtualCards);
      } else {
        allCards = cards;
      }
      this.allCards.set(allCards);
      return allCards;
    }
  }

  public changePin(id: number, pin: number): Promise<void> {
    return this.acpCardClient.changePin({ id, pin });
  }

  public getListOfCardsForSubAccount(id: string): Promise<any> {
    return this.acpCardClient.getListOfCardsForSubAccount({ id });
  }

  public getImage(image: CardImage): string {
    // Actual Implementation returning image path url
    if (!image) {
      return ``;
    }
    const width = this.getImageWidth(image.width.split(','));
    const density = this.getImageDensity(image.density.split(','));
    if (image.url_template.indexOf('/FALLBACK_IMAGES/') !== -1) {
      return this.handleFallbackImage(image, width, density);
    }
    return image.url_template
      .replace(/{DENSITY}/g, density)
      .replace(/{WIDTH}/g, width);
  }

  public getImageWidth(size: string[]): string {
    // based on screen size and available sizes of the image returning best suited image width
    if (this.acpMedia('xs') || this.acpMedia('sm')) {
      return size[0];
    } else if (this.acpMedia('md')) {
      return size[size.length - 2] || size[size.length - 1];
    } else {
      return size[size.length - 1];
    }
  }

  public async cardDeclinedAllTransactions(
    cardDeclineAllTransactionRequest: CardLockRequest
  ): Promise<void> {
    this.cardLockStatus = await this.acpCardClient.cardDeclinedAllTransactions(
      cardDeclineAllTransactionRequest
    );
    return this.cardLockStatus;
  }

  public updateLockStatus(status) {
    this.cardLockStatus = status;
  }

  public isImageVertical(image: CardImage): boolean {
    return image.url_template.indexOf('VERTICAL') > -1;
  }

  private getImageDensity(density: string[]): string {
    // based on screen density and available density of the image returning best suited image density
    const pxRatio = Math.ceil(this.$window.devicePixelRatio) + 'X';
    if (density.indexOf(pxRatio) > -1) {
      return pxRatio;
    }
    return density[density.length - 1];
  }

  private handleFallbackImage(
    image: CardImage,
    width: string,
    density: string
  ): string {
    return image.url_template
      .replace(
        /{ASSOCIATION_SHORT_NAME}/g,
        this.ACP_CARD_CONSTANTS.CARD_ASSOCIATION_SHORT[
          this.$rootScope.branding.association
        ]
      )
      .replace(
        /{BANK_SHORT_NAME}/g,
        this.$rootScope.branding.bank.toUpperCase()
      )
      .replace(/{DENSITY}/g, density)
      .replace(/{WIDTH}/g, width);
  }

  private sortHelper(card1, card2): number {
    if (card1.status === card2.status) {
      return 0;
    } else if (card1.status === this.ACP_CARD_CONSTANTS.CARD_TYPES.ACTIVATED) {
      return -1;
    }
    return 1;
  }

  private filterActivatedLockedCard(card: CardResponse): boolean {
    return (
      card.status === this.ACP_CARD_CONSTANTS.CARD_TYPES.ACTIVATED ||
      card.status === this.ACP_CARD_CONSTANTS.CARD_TYPES.TO_BE_ACTIVATED ||
      card.status === this.ACP_CARD_CONSTANTS.CARD_TYPES.LOCKED
    );
  }

  private addCardCategory(
    cards: CardResponse[],
    category: string
  ): CardResponse[] {
    return cards.map((card) => {
      card.category = category;
      return card;
    });
  }
}
