import { AcpAnalytics } from 'core';
import { AvailableOrderCardStepCollection, OrderCardStep } from './types';

export class AcpProgressBarModel {
  private steps: OrderCardStep[];
  private currentStep: number = 0;
  private cardOwner: string = 'card_holder';

  constructor(
    private ORDER_CARD_STEPS: AvailableOrderCardStepCollection,
    private nsPermissions: nsUtils.NsPermissionsService,
    private $injector: any,
    private ACP_CARD_CONSTANTS: any,
    private acpLogActionClient: any,
    private acpAnalytics: AcpAnalytics
  ) {
    'ngInject';
    this.steps = this.ORDER_CARD_STEPS[this.ACP_CARD_CONSTANTS.DEFAULT].concat(
      this.ORDER_CARD_STEPS[this.ACP_CARD_CONSTANTS.STANDARD]
    );
  }

  public async getSteps(): Promise<OrderCardStep[]> {
    const cardType = this.$injector.get('acpOrderCardModel').getCardType();
    const isDDA: boolean = await this.nsPermissions.requestPermission('isDDA');
    const isCustomcardUnforklifted = await this.nsPermissions.requestPermission(
      'isOac4CustomCardEnabled'
    );
    if (isCustomcardUnforklifted && cardType) {
      const type =
        cardType === this.ACP_CARD_CONSTANTS.PREMIUM
          ? this.ACP_CARD_CONSTANTS.CUSTOM
          : cardType;
      this.steps = this.ORDER_CARD_STEPS[
        this.ACP_CARD_CONSTANTS.DEFAULT
      ].concat(this.ORDER_CARD_STEPS[type]);
    }
    if (!isCustomcardUnforklifted) {
      return this.getStepsIfCustomCardNotUnforklifted(isDDA);
    }
    if (isDDA) {
      return this.getStepsForDDA(this.steps);
    }
    return this.steps;
  }

  public resetStep() {
    this.currentStep = 0;
    this.steps = this.ORDER_CARD_STEPS[this.ACP_CARD_CONSTANTS.DEFAULT].concat(
      this.ORDER_CARD_STEPS[this.ACP_CARD_CONSTANTS.STANDARD]
    );
  }

  public getCurrentStep() {
    return this.currentStep;
  }
  public next() {
    if (this.currentStep < this.steps.length - 1) {
      this.currentStep++;
    }
  }

  public back() {
    if (this.currentStep > 0) {
      this.currentStep--;
    }
    if (this.currentStep === 0) {
        this.acpLogActionClient.logUserAction({
      action: 'CARD_TYPE_PAGE_LOADED'
    });
    this.acpAnalytics.sendEvent(
      'ACP_Cards',
      'Viewed_Choose_Card_Type_Page',
      'acp-landing'
    );
    }
  }

  private getStepsForDDA(steps: OrderCardStep[]): OrderCardStep[] {
    return steps.filter((item) => item.key !== this.cardOwner);
  }

  private getStepsIfCustomCardNotUnforklifted(isDDA): OrderCardStep[] {
    const steps = this.steps.slice(1);
    if (isDDA) {
      return this.getStepsForDDA(steps);
    }
    return steps;
  }
}
