import { AcpProfileClient, ProfileResponse } from 'core';
import { AcpOrderCardClient } from './acp-order-card-client';
import { AcpProgressBarModel } from './acp-progress-bar-model';
import {
  Address,
  CardBrand,
  CardBrandRequest,
  CardBrandResponse,
  CardIssuerNumber,
  CardOwner,
  CardOwnerAddressOptions,
  CardOwnerIdRequest,
  CardOwnerRequest,
  CardOwnerResponse,
  CardShippingMethod,
  CardTypeFeesResponse,
  OrderCardRequest,
  ShippingAddressUpdateRequest,
  ShippingAddressUpdateResponse
} from './types';
export class AcpOrderCardModel {
  private card: any = {};
  constructor(
    private nsProperty: nsUtils.NsPropertyService,
    private acpOrderCardClient: AcpOrderCardClient,
    private acpProgressBarModel: AcpProgressBarModel,
    private nsUtil: nsUtils.NsUtilService,
    private ACP_CARD_CONSTANTS,
    private acpAuthModel: any,
    private acpProfileClient: AcpProfileClient,
    private $location: ng.ILocationService
  ) {
    'ngInject';
    this.card.type = this.nsProperty.create();
    this.card.cardOwner = this.nsProperty.create();
    this.card.selectedCard = this.nsProperty.create();
    this.card.shippingAddress = this.nsProperty.create();
    this.card.fee = this.nsProperty.create();
  }
  public getCardOwnerModel() {
    return this.card.cardOwner.getValue();
  }
  public async setCardOwnerModel(data) {
    this.card.cardOwner.set(data);
    if (data.id !== this.ACP_CARD_CONSTANTS.NEW_CARD_OWNER) {
      const owner = await this.getCardOwner({ id: data.id });
      this.card.cardOwner.set(owner);
    }
  }
  public getCardType() {
    return this.card.type.getValue();
  }
  public setCardType(type) {
    this.card.type.set(type);
  }
  public getCardFee() {
    return this.card.fee.getValue();
  }
  public setCardTypeFee(fee) {
    this.card.fee.set(fee);
  }
  public getSelectedCard() {
    return this.card.selectedCard.getValue();
  }
  public setSelectedCard(data) {
    this.card.selectedCard.set(data);
  }
  public getShippingAddress() {
    return this.card.shippingAddress.getValue();
  }
  public setShippingAddress(data) {
    this.card.shippingAddress.set(data);
  }
  public async getCardOwners(): Promise<CardOwner[]> {
    const cardOwner: CardOwnerResponse = await this.acpOrderCardClient.getCardOwners();
    return cardOwner.owners.sort(this.sortHelper.bind(this));
  }

  public async getCardOwner(
    cardOwnerRequest: CardOwnerIdRequest
  ): Promise<CardOwner> {
    const owner = await this.acpOrderCardClient.getCardOwner(cardOwnerRequest);
    return owner;
  }

  public async getCardShippingMethods(
    selectedCard: CardIssuerNumber | CardBrand
  ): Promise<CardShippingMethod[]> {
    const cardShippingMethods: CardShippingMethod[] = await this.acpOrderCardClient.getCardShippingMethods(
      {
        issuer_number: selectedCard.issuer_number
      }
    );
    return cardShippingMethods.sort(
      (method1, method2) => method1.feeAmount - method2.feeAmount
    );
  }

  public reset(): void {
    this.card.type.reset();
    this.card.cardOwner.reset();
    this.card.selectedCard.reset();
    this.card.shippingAddress.reset();
    this.card.fee.reset();
    this.acpProgressBarModel.resetStep();
  }

  public async getCardBrands(): Promise<CardBrand[]> {
    const payload = {
      type: this.getCardType() || this.ACP_CARD_CONSTANTS.STANDARD
    };
    if (payload.type === this.ACP_CARD_CONSTANTS.PREMIUM) {
      payload.type = this.ACP_CARD_CONSTANTS.CUSTOM;
    }
    return Promise.all([
      this.acpProfileClient.get(),
      this.fetchCardBrands(payload)
    ]).then((result) => {
      return this.callback(result);
    });
  }

  public callback(response: [ProfileResponse, CardBrand[]]): CardBrand[] {
    if (this.getCardType() === this.ACP_CARD_CONSTANTS.PREMIUM) {
      return this.getPremiumCards(response[1]);
    }
    if (
      this.ACP_CARD_CONSTANTS.PREMIUM_CARD_BRAND_PROVIDER.includes(
        response[0].program.brand.id
      ) &&
      this.getCardType() === this.ACP_CARD_CONSTANTS.CUSTOM
    ) {
      return this.getCustomCards(response[1]);
    }
    return response[1];
  }

  public async createOwner(owner: CardOwner): Promise<CardOwner> {
    const payload: CardOwnerRequest = {
      first_name: owner.first_name,
      last_name: owner.last_name,
      email: owner.email,
      address_line1: owner.residential_address.line1,
      zip: owner.residential_address.zip,
      ssn: owner.ssn,
      dob: this.formatDate(owner.dob)
    };
    if (owner.phone) {
      payload.phone = owner.phone;
    }
    if (owner.residential_address.line2) {
      payload.address_line2 = owner.residential_address.line2;
    }

    const cardOwner: CardOwner = await this.acpOrderCardClient.createCardOwners(
      payload
    );
    return cardOwner;
  }

  public async updateShippingAddress(
    owner: CardOwner | CardOwnerIdRequest,
    address: Address
  ): Promise<ShippingAddressUpdateResponse> {
    const payload: ShippingAddressUpdateRequest = {
      id: owner.id,
      ship_to_address_line1: address.line1,
      ship_to_zip: address.zip
    };
    if (address.line2) {
      payload.ship_to_address_line2 = address.line2;
    }

    const addressUpdate = await this.acpOrderCardClient.updateShippingAddress(
      payload
    );
    return addressUpdate;
  }

  public async placeOrder(
    owner: CardOwner | CardOwnerIdRequest,
    cardBrand: CardBrand | CardIssuerNumber,
    cardShippingMethod: CardShippingMethod,
    orderReason?: string
  ): Promise<void> {
    const payload: OrderCardRequest = {
      id: owner.id,
      issuer_number: cardBrand.issuer_number,
      order_reason: orderReason
        ? orderReason
        : this.ACP_CARD_CONSTANTS.ORDER_REASON.ADDITIONAL_CARD
    };
    if (
      this.getCardType() &&
      this.getCardType() !== this.ACP_CARD_CONSTANTS.STANDARD
    ) {
      payload.order_reason = this.ACP_CARD_CONSTANTS.ORDER_REASON.CUSTOM_CARD;
      payload.image_ref_id = cardBrand.image_ref_id;
    }
    // shipping details is only needed for standard card order
    if (
      !this.getCardType() ||
      this.getCardType() === this.ACP_CARD_CONSTANTS.STANDARD
    ) {
      if (cardShippingMethod.id) {
        payload.shipping_method = cardShippingMethod.id;
      } else if (cardShippingMethod.feeName) {
        payload.fee_amount = cardShippingMethod.feeAmount;
        payload.fee_type = cardShippingMethod.feeName;
      }
    }
    await this.acpOrderCardClient.orderCard(payload);
  }

  public async orderCardForSubaccount(
    accountId: { id: string },
    owner: CardOwner | CardOwnerIdRequest,
    cardBrand: CardBrand | CardIssuerNumber,
    cardShippingMethod: CardShippingMethod
  ): Promise<void> {
    const payload = {
      subAccId: accountId.id,
      id: owner.id,
      issuer_number: cardBrand.issuer_number,
      shipping_method: cardShippingMethod.id
    };
    await this.acpOrderCardClient.orderCardForSubaccount(payload);
  }

  public setAddressOptions(
    cardOwnerDetails: CardOwner
  ): CardOwnerAddressOptions[] {
    const addressOptions = [];
    cardOwnerDetails.new_address = new Address();
    if (cardOwnerDetails.ship_to_address) {
      addressOptions.push({
        name: this.ACP_CARD_CONSTANTS.SHIPPING_ADDRESS_OPTIONS
          .SEND_TO_ADDRESS_ON_FILE,
        value: this.ACP_CARD_CONSTANTS.SHIPPING_ADDRESS_OPTIONS.SHIP_TO_ADDRESS
      });
    }

    if (
      !cardOwnerDetails.ship_to_address &&
      cardOwnerDetails.residential_address
    ) {
      addressOptions.push({
        name: this.ACP_CARD_CONSTANTS.SHIPPING_ADDRESS_OPTIONS
          .SEND_TO_ADDRESS_ON_FILE,
        value: this.ACP_CARD_CONSTANTS.SHIPPING_ADDRESS_OPTIONS
          .RESIDENTIAL_ADDRESS
      });
    }

    addressOptions.push({
      name: this.ACP_CARD_CONSTANTS.SHIPPING_ADDRESS_OPTIONS
        .SEND_TO_DIFFERENT_ADDRESS,
      value: this.ACP_CARD_CONSTANTS.SHIPPING_ADDRESS_OPTIONS.NEW_ADDRESS
    });

    return addressOptions;
  }

  public async getCardTypeFee(): Promise<CardTypeFeesResponse> {
    return await this.acpOrderCardClient.getCardTypeFee();
  }

  public convertCentToDollar(amount: number): number {
    return this.nsUtil.toDollars(amount);
  }

  public isSecurityCheckRequired(): boolean {
    const securityDataLocker = this.acpAuthModel.info.getValue();
    if (securityDataLocker) {
      return !(securityDataLocker.questions || securityDataLocker.ooba);
    }
    return true;
  }

  public getOrigin(): string {
    // regex used to extract the host name(i.e. https://www-netspend-c.test.aus.netspend.net) out of the url.
    return this.$location.absUrl().match(/^.+:\/\/[^\/]+/)[0];
  }

  public async fetchCardBrands(
    payload: CardBrandRequest
  ): Promise<CardBrand[]> {
    const cardOwner: CardBrandResponse = await this.acpOrderCardClient.getCardBrands(
      payload
    );
    return cardOwner.brands;
  }

  private sortHelper(owner1: CardOwner, owner2: CardOwner): number {
    if (owner1.primary === owner2.primary) {
      return 0;
    } else if (owner1.primary) {
      return -1;
    }
    return 1;
  }

  private formatDate(date: string) {
    return `${date.substring(0, 2)}/${date.substring(2, 4)}/${date.substring(
      4,
      8
    )}`;
  }

  private getMinPriority(brands: CardBrand[]): number {
    return brands.reduce((start, item, index) => {
      if (index === 0) {
        start = item.display_priority;
      }
      if (start > item.display_priority) {
        start = item.display_priority;
      }
      return start;
    }, 0);
  }

  private getPremiumCards(brands: CardBrand[]): CardBrand[] {
    const minPriority: number = this.getMinPriority(brands);
    return brands.filter((item) => item.display_priority > minPriority);
  }

  private getCustomCards(brands: CardBrand[]): CardBrand[] {
    const minPriority: number = this.getMinPriority(brands);
    return brands.filter((item) => item.display_priority === minPriority);
  }
}
