import ng from 'angular';
import acpCore from 'core';
import { AcpCardClient } from './acp-card-client';
import { AcpCardModel } from './acp-card-model';
import { AcpOrderCardClient } from './acp-order-card-client';
import { ORDER_CARD_STEPS } from './acp-order-card-flow-data-constant';
import { AcpOrderCardModel } from './acp-order-card-model';
import { AcpProgressBarModel } from './acp-progress-bar-model';

import acpVirtualCardDomain from 'components/virtual-cards-domain';
import acpCardConstants from './acp-card-constant';
export const acpCardDomainModule = ng
  .module('acp.component.card-domain', [
    acpCore.name,
    acpVirtualCardDomain.name
  ])
  .service('acpCardModel', AcpCardModel)
  .service('acpCardClient', AcpCardClient)
  .service('acpOrderCardModel', AcpOrderCardModel)
  .service('acpOrderCardClient', AcpOrderCardClient)
  .service('acpProgressBarModel', AcpProgressBarModel)
  .constant('ACP_CARD_CONSTANTS', acpCardConstants)
  .constant('ORDER_CARD_STEPS', ORDER_CARD_STEPS);

export default acpCardDomainModule;

export { AcpCardModel, AcpOrderCardModel, AcpProgressBarModel };
export * from './types';
