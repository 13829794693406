import {
  CardBrandRequest,
  CardBrandResponse,
  CardIssuerNumber,
  CardOwner,
  CardOwnerIdRequest,
  CardOwnerRequest,
  CardOwnerResponse,
  CardShippingMethod,
  CardTypeFeesResponse,
  OrderCardRequest,
  ShippingAddressUpdateRequest,
  ShippingAddressUpdateResponse
} from './types';

export class AcpOrderCardClient {
  public getCardOwners = this.webapiResource<string, CardOwnerResponse>({
    method: 'GET',
    path: '/v1/cardowners'
  });

  public getCardShippingMethods = this.webapiResource<
    CardIssuerNumber,
    CardShippingMethod[]
  >({
    method: 'GET',
    path: '/v1/cardowners/shipping/options/:issuer_number'
  });

  public getCardBrands = this.webapiResource<
    CardBrandRequest,
    CardBrandResponse
  >({
    method: 'GET',
    path: '/v1/cards/brands/:type'
  });

  public createCardOwners = this.webapiResource<CardOwnerRequest, CardOwner>({
    method: 'PUT',
    path: '/v1/cardowners',
    transformData(response) {
      return response.owner;
    }
  });

  public updateShippingAddress = this.webapiResource<
    ShippingAddressUpdateRequest,
    ShippingAddressUpdateResponse
  >({
    method: 'PUT',
    path: '/v1/cardowners/:id'
  });

  public orderCard = this.webapiResource<OrderCardRequest, void>({
    method: 'PUT',
    path: '/v1/cardowners/:id/ordercard'
  });

  public getCardOwner = this.webapiResource<CardOwnerIdRequest, CardOwner>({
    method: 'GET',
    path: '/v1/cardowners/:id'
  });

  public orderCardForSubaccount = this.webapiResource<OrderCardRequest, void>({
    format: 'json',
    method: 'PUT',
    path: '/v2/subaccounts/:subAccId/ordercard'
  });

  public getCardTypeFee = this.webapiResource<void, CardTypeFeesResponse>({
    path: '/v1/feeplan',
    method: 'GET'
  });

  constructor(private webapiResource: nsWebclient.WebapiResourceService) {
    'ngInject';
  }
}
