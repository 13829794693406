import { AvailableOrderCardStepCollection } from './types';

export const ORDER_CARD_STEPS: AvailableOrderCardStepCollection = {
  default: [
    {
      key: 'order_card_landing',
      name: 'OrderCardLanding',
      pagination: true,
      component: '<acp-order-card-landing></acp-order-card-landing>'
    }
  ],
  standard: [
    {
      key: 'card_holder',
      name: 'CardHolder',
      pagination: true,
      component: '<acp-select-card-owner></acp-select-card-owner>'
    },
    {
      key: 'select_card',
      name: 'SelectCard',
      pagination: true,
      component: '<acp-select-card-design></acp-select-card-design>'
    },
    {
      key: 'shipping_address',
      name: 'ShippingAddress',
      pagination: true,
      component: '<acp-select-shipping-address></acp-select-shipping-address>'
    },
    {
      key: 'order_summary',
      name: 'OrderSummary',
      pagination: true,
      component: '<acp-order-card-summary></acp-order-card-summary>'
    }
  ],
  custom: [
    {
      key: 'common_question',
      name: 'CommonQuestion',
      pagination: true,
      component:
        '<acp-order-card-common-question></acp-order-card-common-question>'
    },
    {
      key: 'card_holder',
      name: 'CardHolder',
      pagination: true,
      component: '<acp-select-card-owner></acp-select-card-owner>'
    },
    {
      key: 'select_card',
      name: 'SelectCard',
      pagination: true,
      component: '<acp-select-card-design></acp-select-card-design>'
    },
    {
      key: 'card_design',
      name: 'CardDesign',
      pagination: true,
      component: '<acp-card-design></acp-card-design>'
    },
    {
      key: 'shipping_address',
      name: 'ShippingAddress',
      pagination: true,
      component: '<acp-select-shipping-address></acp-select-shipping-address>'
    },
    {
      key: 'order_summary',
      name: 'OrderSummary',
      pagination: true,
      component: '<acp-order-card-summary></acp-order-card-summary>'
    }
  ]
};
