export default {
  CARD_TYPES: {
    ACTIVATED: 'activated',
    TO_BE_ACTIVATED: 'unactivated',
    IN_ACTIVE: 'inactive',
    ACTIVE: 'active',
    LOST_STOLEN: 'lost_stolen',
    LOCKED: 'locked'
  },
  MAX_ALLOWED_CARD: 3,
  MAX_CARDOWNERS_ALLOWED: 3,
  MAX_ALLOWED_CARD_IN_WORD: 'three',
  CARD_CATEGORY: {
    VIRTUAL: 'virtual',
    PHYSICAL: 'physical'
  },
  INACTIVE_ALL_CARDS: 'all',
  INACTIVE_PHYSICAL_CARDS: 'inactivePhysicalCards',
  INACTIVE_VIRTUAL_CARDS: 'inactiveVirtualCards',
  CARD_ASSOCIATION: {
    VISA_CARD: 'visa',
    MASTER_CARD: 'mastercard'
  },
  FEE_AMOUNT_ZERO: 0,
  NEW_CARD_OWNER: 'new-owner',
  EXPECTED_ARRIVAL_MAX_DAYS: 12,
  DATE_FORMAT: 'MM/DD/YYYY',
  SHIPPING_ADDRESS_OPTIONS: {
    SEND_TO_ADDRESS_ON_FILE: 'Send to Address on File',
    SEND_TO_DIFFERENT_ADDRESS: 'Send to a Different Address',
    SHIP_TO_ADDRESS: 'ship_to_address',
    RESIDENTIAL_ADDRESS: 'residential_address',
    NEW_ADDRESS: 'new_address'
  },
  PROGRAM_TYPE: {
    CONSUMER_DDA: 'consumer_dda',
    BAW_DDA: 'baw_dda'
  },
  CARD_ASSOCIATION_SHORT: {
    mastercard: 'MC',
    visa: 'VISA'
  },
  STANDARD: 'standard',
  CUSTOM: 'custom',
  PREMIUM: 'premium',
  DEFAULT: 'default',
  PREMIUM_CARD_BRAND_PROVIDER: ['ace_flare'],
  ORDER_REASON: {
    ADDITIONAL_CARD: 'AdditionalCard',
    CUSTOM_CARD: 'CustomCard',
    REPLACEMENT_CARD: 'ReplacementCard'
  }
};
