import { CardLockRequest } from './types/acp-card-lock-request';
export class AcpCardClient {
  public changePin = this.webapiResource<{ id: number; pin: number }, void>({
    method: 'PUT',
    path: '/v1/cards/:id/pin'
  });

  public getListOfCardsForSubAccount = this.webapiResource({
    method: 'GET',
    path: '/v1/subaccounts/:id/cards'
  });

  public cardDeclinedAllTransactions = this.webapiResource<
    CardLockRequest,
    void
  >({
    method: 'POST',
    path: '/v2/cards/restrictions',
    format: 'json'
  });

  constructor(private webapiResource: nsWebclient.WebapiResourceService) {
    'ngInject';
  }
}
